<template>
    <div class="casehistory-main">
        <div class="mian-container">
            <div class="container-card">
                <p class="title">
                    {{medicalInfo.tenantName}}
                </p>
                <p class="case-typeName">
                    <img src="@/assets/img/hosp-icon.png" class="icon">
                    <span class="txt">{{caseHistoryHead[caseInfo.type]}}</span>
                </p>
                <div class="hosp-info" v-if="medicalInfo.type == 137002">
                    <p class="bednumAndhospital">
                        {{$t("patient.bednum")}}：{{medicalInfo.bedNumber}}
                    </p>
                    <p class="bednumAndhospital">
                        {{$t("patient.hospital")}}：{{medicalInfo.hospitalNumber}}
                    </p>
                </div>
                <div class="hosp-info" v-if="medicalInfo.type != 137002">
                    <p class="bednumAndhospital nocontent">
                    </p>
                    <p class="bednumAndhospital">
                        {{$t("patient.clinicNumber")}}：{{medicalInfo.clinicNumber}}
                    </p>
                </div>
                <div class="info-detail">
                    <div class="detail-item">
                        <p class="items">
                            {{$t("patient.patientName")}}：{{medicalInfo.patientName}}
                        </p>
                        <p class="items">
                            {{$t("patient.gender")}}：{{medicalInfo.patientAge}}{{$t("patient.annum")}}
                        </p>
                        <p class="items">
                            {{$t("patient.gender")}}：{{medicalInfo.patientGender==1?$t("patient.man"):$t("patient.woman")}}
                        </p>
                    </div>
                    <div class="detail-item" v-if="medicalInfo.type == 137002">
                        <p class="items">
                            {{$t("patient.department")}}：{{medicalInfo.deptName}}
                        </p>
                        <p class="items">
                            {{$t("patient.ward")}}：{{medicalInfo.zoneName}}
                        </p>
                    </div>
                     <div class="detail-item" v-if="medicalInfo.type == 137002">
                        <p class="items">
                            {{$t("patient.directordoctor")}}：{{medicalInfo.doctorName}}
                        </p>
                        <p class="items">
                            {{$t("patient.dutynurse")}}：{{medicalInfo.nurseName}}
                        </p>
                    </div>
                     <div class="detail-item" v-if="medicalInfo.type == 137002">
                        <p class="items">
                            {{$t("patient.hosptime")}}：{{getLocalMinutesTime(medicalInfo.inHospitalDt)}}
                        </p>
                    </div>
                     <div class="detail-item" v-if="medicalInfo.type != 137002">
                        <p class="items">
                            {{$t("patient.clicpDictor")}}：{{medicalInfo.doctorName}}
                        </p>
                        <p class="items">
                            {{$t("patient.assistantdoctor")}}：{{medicalInfo.nurseName}}
                        </p>
                    </div>
                    
                    <div class="detail-item" v-if="medicalInfo.type != 137002">

                        <p class="items">
                            {{$t("patient.department")}}：{{medicalInfo.deptName}}
                        </p>
                    </div>
                    <div class="detail-item" v-if="medicalInfo.type != 137002">
                        <p class="items">
                            {{$t("patient.clinicTime",{time:""})}}{{getLocalMinutesTime(medicalInfo.clinicDt)}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="container-section">
               
                <template v-for="(item,index) in caseInfo.traceKvs">
                    <div class="sectboxitem" :key="index" v-if="item.type == 'text'">
                        <p class="sect-title">{{item.title}}</p>
                        <p class="sect-desc">{{item.content}}</p>
                    </div>
                    <div class="sectboxitem" v-if="item.type == 'img'" :key="index">
                        <p class="sect-title">{{$t("patient.photo")}}</p>
                        <div class="sect-desc item-img">
                            <img class="imgitem" :src="citem" @click="seeImg(item.imgs,cindex)" v-for="(citem,cindex) in item.imgs" :key="cindex" >
                        </div>
                    </div>
                </template>
    
                <!-- <div class="sect-hr"></div>
                <div class="sect-recordtime">
                    <p class="time-txt">
                        <span class="lable">{{$t("patient.recordtime")}}：</span>
                        <span class="value">{{getLocalMinutesTime(caseInfo.traceDt)}}</span>
                    </p>
                </div> -->
            </div>
        </div>
        <div class="bottom-btn" v-if="caseInfo.fromUserId == $store.getters.id">
            <span class="btn-item" @click="deletecase">{{$t("patient.deletebtn")}}</span>
            <span class="btn-item primary" @click="editCase">{{$t("patient.editbtn")}}</span>
        </div>
    </div>
</template>

<script>
import { traceGet, medicalDetail, traceDel } from "@/api/patient";
import { getLocalMinutesTime } from "@/utils/date";
import { ImagePreview } from 'vant';

export default {
    data(){
        return{
            medicalId:this.$route.query.medicalId,
            caseId:this.$route.query.caseId,
            medicalInfo:{},
            caseInfo:{},
            getLocalMinutesTime,
            caseHistoryHead:{
                "138001":this.$t("patient.caseHistoryHead1"),
                "138002":this.$t("patient.caseHistoryHead2"),
                "138003":this.$t("patient.caseHistoryHead3"),
                "138004":this.$t("patient.caseHistoryHead4"),
                "138005":this.$t("patient.caseHistoryHead5"),
                "138006":this.$t("patient.caseHistoryHead6"),
            }
        }
    },
    async created(){
        document.title = this.$t("patient.caseDetail")
        const toast = this.$Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: this.$t("message.loadingTxt")
        });
        await this.getcaseHistoryInfo();
        await this.getMedicalData();
        toast.close()
    },
    methods:{
        async getcaseHistoryInfo(){
            
            let result = await traceGet({
                traceId:this.caseId
            })
            if(result.data.errorCode == 0){
                this.caseInfo = result.data.data;
            }
            // caseInfo
            
        },
        async getMedicalData(){
            
            let result = await medicalDetail({id:this.medicalId});
            if(result.data.errorCode == 0){
                this.medicalInfo = result.data.data;
            }
        },
        seeImg(imgs,index){
            console.log(imgs);
            ImagePreview({
                images: imgs,
                closeable: true,
                startPosition: index,
            });
        },
        // 删除病历
        async deletecase(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: this.$t("message.loadingTxt")
            });
            let result = await  traceDel({
                id:this.caseId
            })
            toast.close()
            if(result.data.errorCode == 0){
                this.$router.go(-1)
            }
        },
        // 编辑
        editCase(){
            this.$router.push({
                path:"/patient/addcustomcaseHistory",
                query:{
                    medicalId:this.medicalId,
                    caseId:this.caseId
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.casehistory-main{
    min-height: 100%;
    overflow: auto;
    background-color: #fff;
    background-image: url("~@/assets/img/casehistory-detail-back.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% auto;
    padding: 1rem 0.6rem;
    box-sizing: border-box;
    .mian-container{
        background: #FFFFFF;
        border: 0.02rem solid;
        border-image: linear-gradient(0deg, #DDDDDD, #F2F2F2) 10 10;
        box-shadow: 0 0.08rem 0.24rem 0 rgba(203, 219, 235, 0.55);
        border-radius: 0.32rem;
        position: relative;
        &::after,&::before{
            position: absolute;
            top: 0;
            transform: translate(0,-50%);
            left: 0.6rem;
            width: 0.2rem;
            content: ' ';
            height: 0.46rem;
            background: url("~@/assets/img/paper-clip-icon.png") no-repeat;
            background-size: 100% 100%;
        }
        &::before{
            left: unset;
            right: 0.6rem;
        }
        .container-card{
            background: #FFFFFF;
            border: 0.02rem solid;
            border-image: linear-gradient(0deg, #DDDDDD, #F2F2F2) 10 10;
            box-shadow: 0 0.08rem 0.24rem 0 rgba(203, 219, 235, 0.55);
            border-radius: 0.32rem;
            padding: 0.4rem;text-align: center;
            .title{
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #999999;
                margin: 0.2rem 0 0.6rem 0;
                text-align: center;
                letter-spacing: 0.2rem;
                // letter-spacing:25px;
                // text-align: justify;
                // text-align-last: justify;
                // text-justify: inter-ideograph;
                // display: flex;
                // justify-content: space-between;
            }
            .case-typeName{
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 0.32rem 0;
                border-bottom:0.04rem solid #C7C8C9;
                .icon{
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-right: 0.1rem;
                }
                .txt{
                    font-size: 0.84rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333; 
                }
            }
            .hosp-info{
                display: flex;
                justify-content: space-between;
                padding: 0.6rem 0 0.32rem 0;
                align-items: center;
                .bednumAndhospital{
                    padding-left: 0.5rem;
                    font-size: 0.52rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    position: relative;
                    &::after{
                        border-radius: 50%;
                        top: 50%;
                        left: 0;
                        transform: translate(0,-50%);
                        position: absolute;
                        width: 0.24rem;
                        height: 0.24rem;
                        content: ' ';
                        background: #49A3FF;
                    }
            
                }
                .nocontent::after{
                    display: none;
                }
            }
            .info-detail{
                background: #F5F6F9;
                padding: 0.4rem;
                .detail-item{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 0.5rem;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .items{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
        .container-section{
            padding: 0.8rem 0.8rem 1rem 0.8rem;
            .sectboxitem{
                margin-bottom: 0.9rem;
                .sect-title{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    margin-bottom: 0.44rem;
                }
                .sect-desc{
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #333333;
                }
                .item-img{
                    display: flex;
                    flex-wrap: wrap;
                    .imgitem{
                        width: 3.9rem;
                        height: 3.9rem;
                        margin-left: 0.2rem;
                        margin-bottom: 0.2rem;
                        border-radius: 0.1rem;
                        &:nth-child(3n+1){
                            margin-left: 0;
                        }
                    }
                }
            }
            .sect-hr{
                width: 100%;
                border: 0.02rem dashed #C7C8C9;
                margin: 0 auto;
                position: relative;
                &::after{
                    content: ' ';
                    width: 0.3rem;
                    height: 0.6rem;
                    position: absolute;
                    border: 0.02rem solid #C7C8C9;
                    border-radius: 0 0.6rem 0.6rem 0;
                    border-left: 0.02rem solid #fff;
                    position:absolute;
                    left: -0.9rem;
                    top: 50%;
                    background: #fff;
                    transform: translate(0,-50%);
                }
                &::before{
                    content: ' ';
                    width: 0.3rem;
                    height: 0.6rem;
                    position: absolute;
                    border: 0.02rem solid #C7C8C9;
                    border-radius: 0.6rem 0 0 0.6rem;
                    border-right: 0.02rem solid #fff;
                    position:absolute;
                    right: -0.9rem;
                    top: 50%;
                    background: #fff;
                    transform: translate(0,-50%);
                }
            }
            .sect-recordtime{
                margin-top: 1.2rem;
                .time-txt{
                    text-align: right;
                    .lable{
                        font-size: 0.64rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                    .value{
                        font-size: 0.64rem;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }
                }
            }
        }
    }
    .bottom-btn{
        margin: 1.2rem 0.6rem;
        display: flex;
        .btn-item{
            flex: 1;
            margin-left: 0.6rem;
            height: 1.8rem;
            line-height: 1.8rem;
            font-size: 0.76rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #838385;
            text-align: center;
            border: 0.02rem solid #838385;
            border-radius: 0.9rem;
            &:first-child{
                margin-left: 0;
            }
        }
        .primary{
            background: linear-gradient(0deg, #499FFF 0%, #49BFFF 100%);
            border: 0;
            color: #fff;
        }
    }

}
</style>